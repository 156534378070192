<template>
  <div class="common-detail-view W100">
    <ak-container
      type="white"
      class="header-info-main"
      card
      :bodyStyle="{ padding: 0 }"
      :loading="loading"
    >
      <!-- 个人信息-->
      <DetailTop :businessResp="businessResp" :personalResp="personalResp"/>

      <div class="authInfo">
        <div class="bussinessReal">
          <a-card>
            <div
              class="F16 F-BOLD"
              style="font-weight: bold; margin-bottom: 10px"
            >
              企业真实性认证信息
            </div>
            <a-row :gutter="gutter" class="PB16">
              <a-col :span="4">
                <span>企业名称 </span>
              </a-col>
              <a-col :span="20">{{ realBusiCertification.enterpriseName }}</a-col>
            </a-row>
            <a-row v-if="!realBusiCertification">营业执照</a-row>
            <a-row v-if="!realBusiCertification">
              <viewer
                :images="[
                  `${$const.OssImagePrefix}/${realBusiCertification.busiLicensePic}`,
                ]"
              >
                <img
                  class="detail-img"
                  :src="`${$const.OssImagePrefix}/${realBusiCertification.busiLicensePic}`"
                />
              </viewer>
            </a-row>
            <a-row :gutter="gutter" class="PB16" v-if="!realBusiCertification">
              <a-col :span="4">
                <span>社会统一信用代码</span>
              </a-col>
              <a-col :span="20">{{
                realBusiCertification.busiLicenseCode
              }}</a-col>
            </a-row>
            <a-row :gutter="gutter" class="PB16">
              <a-col :span="4">
                <span>营业执照有效期</span>
              </a-col>
              <a-col :span="20">{{
                realBusiCertification.busiLicenseAvailable
              }}</a-col>
            </a-row>
            <a-row :gutter="gutter" class="PB16">
              <a-col :span="4">
                <span>法人姓名</span>
              </a-col>
              <a-col :span="20">{{
                realBusiCertification.legalmanLicenceName
              }}</a-col>
            </a-row>
            <a-row>法人身份证</a-row>
            <a-row :gutter="gutter" class="PB16">
              <a-col :span="6" class="img-padding" v-if="realNameCertification&&realNameCertification.handIdentityCard !== ''">
                <viewer
                  :images="[
                    `${$const.OssImagePrefix}/${realNameCertification.handIdentityCard}`,
                  ]"
                >
                  <img
                    class="detail-img"
                    :src="`${$const.OssImagePrefix}/${realNameCertification.handIdentityCard}`"
                  />
                </viewer>
              </a-col>
              <a-col :span="6" class="img-padding" v-if="realNameCertification&&realNameCertification.frontIdentityCard !== ''">
                <viewer
                  :images="[
                    `${$const.OssImagePrefix}/${realNameCertification.frontIdentityCard}`,
                  ]"
                >
                  <img
                    class="detail-img"
                    :src="`${$const.OssImagePrefix}/${realNameCertification.frontIdentityCard}`"
                  />
                </viewer>
              </a-col>
              <a-col :span="6" v-if="realNameCertification&&realNameCertification.backIdentityCard !== ''">
                <viewer
                  :images="[
                    `${$const.OssImagePrefix}/${realNameCertification.backIdentityCard}`,
                  ]"
                >
                  <img
                    class="detail-img"
                    :src="`${$const.OssImagePrefix}/${realNameCertification.backIdentityCard}`"
                  />
                </viewer>
              </a-col>
            </a-row>
            <!-- <a-row :gutter="gutter" class="PB16">
              <a-col :span="4">
                <span>运营者姓名</span>
              </a-col>
              <a-col :span="20">运营者</a-col>
            </a-row>
            <a-row :gutter="gutter" class="PB16">
              <a-col :span="4">
                <span>运营者联系方式</span>
              </a-col>
              <a-col :span="20">15221964961</a-col>
            </a-row>
            <a-row :gutter="gutter" class="PB16">
              <a-col :span="4">
                <span>运营者邮箱</span>
              </a-col>
              <a-col :span="20">15221964961@qq.com</a-col>
            </a-row>
            <a-row :gutter="gutter" class="PB16">
              <a-col :span="4">
                <span>运营者微信</span>
              </a-col>
              <a-col :span="20">aaacheng</a-col>
            </a-row> -->
          </a-card>
        </div>
        <div class="infoCertification">
          <a-card>
            <div
              class="F16 F-BOLD"
              style="font-weight: bold; margin-bottom: 10px"
            >
              简介认证
            </div>
            <p>
              {{ authentication && authentication.introduction }}
            </p>
            <div
              class="F16 F-BOLD"
              style="font-weight: bold; margin-bottom: 10px"
            >
              品牌认证
            </div>
            <div class="brandCertification" v-if="authentication && authentication.brandCertification">
              <a-row :gutter="gutter" class="PB16">
                <a-col :span="6">商标注册证</a-col>
                <a-col :span="6"
                  >商标名称：{{
                    authentication && authentication.brandCertification.brandName
                  }}</a-col
                >
              </a-row>
              <a-row :gutter="gutter" class="PB16">
                <a-col :span="6">
                  <viewer
                    :images="[
                      `${$const.OssImagePrefix}/${authentication?.brandCertification?.brandRegistPic}`,
                    ]"
                  >
                    <img
                      class="detail-img"
                      :src="`${$const.OssImagePrefix}/${authentication?.brandCertification?.brandRegistPic}`"
                    />
                  </viewer>
                </a-col>
                <a-col :span="6">
                  <viewer
                    :images="[
                      `${$const.OssImagePrefix}/${authentication?.brandCertification?.brandLogoPic}`,
                    ]"
                  >
                    <img
                      class="detail-img"
                      :src="`${$const.OssImagePrefix}/${authentication?.brandCertification?.brandLogoPic}`"
                    />
                  </viewer>
                </a-col>
              </a-row>
              <div
                class="F16 F-BOLD"
                style="font-weight: bold; margin-bottom: 10px"
              >
                商标授权书
              </div>
              <a-row :span="6">
                <viewer
                  :images="[
                    `${$const.OssImagePrefix}/${authentication?.brandCertification?.brandAuthorizePic}`,
                  ]"
                >
                  <img
                    class="detail-img"
                    :src="`${$const.OssImagePrefix}/${authentication?.brandCertification?.brandAuthorizePic}`"
                  />
                </viewer>
              </a-row>
            </div>
            <div class="addressCertification" v-if="authentication && authentication.addressCertification">
              <div
                class="F16 F-BOLD"
                style="font-weight: bold; margin-bottom: 10px"
              >
                线下实体店认证
              </div>
              <p>
                {{
                  authentication && authentication.addressCertification.shopAdress
                }}
              </p>
              <p v-if="authentication.addressCertification">
                营业中 &nbsp;&nbsp;
                {{ authentication.addressCertification.workDaysBegin }} -
                {{ authentication.addressCertification.workDaysEnd }}
                {{ authentication.addressCertification.workTimeBegin }} -
                {{ authentication.addressCertification.workTimeEnd }}
              </p>
            </div>
            <div
              class="F16 F-BOLD"
              style="font-weight: bold; margin-bottom: 10px"
            >
              店铺环境认证
            </div>
            <a-row :gutter="gutter" class="PB16" v-if="authentication && authentication.officeEnvironmentCertification">
              <a-col
                :span="6"
                v-for="expImg in authentication.officeEnvironmentCertification"
                :key="expImg"
              >
                <viewer :images="[`${$const.OssImagePrefix}/${expImg}`]">
                  <img class="detail-img" :src="`${$const.OssImagePrefix}/${expImg}`" />
                </viewer>
              </a-col>
            </a-row>
            <div
              class="F16 F-BOLD"
              style="font-weight: bold; margin-bottom: 10px"
            >
              类目认证
            </div>
            <div
              v-for="exp in authentication &&
              authentication.experienceCertifications"
              :key="exp"
            >
              <div
                class="F16 F-BOLD"
                style="font-weight: bold; margin-bottom: 10px"
              >
                报关单
              </div>
              <p>{{ exp && exp.timePeriod }} {{ exp && exp.title }}</p>
              <a-row :gutter="gutter" class="PB16">
                <a-col :span="6">
                  <ak-viewer v-model="fileList" class="fileList" />
                </a-col>
                <a-col :span="6"
                  ><ak-viewer v-model="fileList" class="fileList"
                /></a-col>
                <a-col :span="6"
                  ><ak-viewer v-model="fileList" class="fileList"
                /></a-col>
              </a-row>
            </div>
            <div
              v-for="awa in authentication && authentication.awardCertifications"
              :key="awa"
            >
              <div
                class="F16 F-BOLD"
                style="font-weight: bold; margin-bottom: 10px"
              >
                质检报告
              </div>
              <p>{{ awa && awa.timePeriod }} {{ awa && awa.title }}</p>
              <a-row :gutter="gutter" class="PB16">
                <a-col :span="6">
                  <ak-viewer v-model="fileList" class="fileList" />
                </a-col>
                <a-col :span="6"
                  ><ak-viewer v-model="fileList" class="fileList"
                /></a-col>
                <a-col :span="6"
                  ><ak-viewer v-model="fileList" class="fileList"
                /></a-col>
              </a-row>
            </div>
            <div
              v-for="exh in authentication &&
              authentication.exhibitionCertifications"
              :key="exh"
            >
              <div
                class="F16 F-BOLD"
                style="font-weight: bold; margin-bottom: 10px"
              >
                CCC安全认证证书
              </div>
              <p>{{ exh && exh.timePeriod }} {{ exh && exh.title }}</p>
              <a-row :gutter="gutter" class="PB16">
                <a-col :span="6">
                  <ak-viewer v-model="fileList" class="fileList" />
                </a-col>
                <a-col :span="6"
                  ><ak-viewer v-model="fileList" class="fileList"
                /></a-col>
                <a-col :span="6"
                  ><ak-viewer v-model="fileList" class="fileList"
                /></a-col>
              </a-row>
            </div>
          </a-card>
        </div>
      </div>
    </ak-container>
  </div>
</template>

<script>
import * as Business from "@/api/user/business";
import DetailTop from '../../components/detail-top.vue'

export default {
  components: { DetailTop },
  data() {
    return {
      gutter: 24,
      loading: false,
      detailData: null,
      realNameCertification: null,
      realBusiCertification: {},
      authentication: null,
      businessResp: {},
      personalResp: {},
    };
  },
  created() {
    this.userId = this.$route.query.id;
    this.queryDetail();
  },
  methods: {
    queryDetail() {
      this.loading = true;
      const param = {
        userId: this.userId,
      };
      Business.queryBusinessById(param)
        .then((res) => {
          const data = res.data || {};
          this.detailData = data || {};
          this.realNameCertification = data.realNameCertification || {};
          this.realBusiCertification = JSON.parse(JSON.stringify(data.realBusiCertification)) || {};
          this.authentication = data.authentication || {};
          // this.getValue(data, [
          //   'realNameCertification',
          //   'realBusiCertification',
          //   'authentication',
          //   'brandCertification'
          // ]);
          console.log('realBusiCertification', this.realBusiCertification)
          this.businessResp = res.data.business || {}
          this.personalResp = res.data.personalResp || {}
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getValue(data, keys) {
      for (let key of keys) {
        if (data[key] === undefined) {
          return undefined;
        } else {
          data = data[key];
        }
      }
      return data;
    },
  },
};
</script>
<style>
.create-bk-card {
  margin-top: 20px;
}
.bk_creater {
  width: 100%;
  height: 400px;
  background: rgb(237, 240, 245) url(../../../assets/bk_creater.png) 0 0
    no-repeat;
}
.bussiness,
.bussinessReal,
.infoCertification {
  margin-top: 20px;
}
.fileList .ak-viewer-wrap .preview-thumbnail-list .list-item img {
  width: 300px !important;
  height: 400px !important;
}
.authInfo{
  padding:0 20px 20px 20px;
}
</style>
<style lang="less" scoped>
.common-detail-view {
    .C666 {
      display: inline-block;
      width: 90px;
      text-align: right;
    }
}
.left-detail-card {
  width: 100%;
}
/deep/ .bindUser .ant-card-body {
  padding: 24px 0 6px 24px;
}
.ant-col {
  margin-bottom: 8px;
}
.ant-col:last-child {
  margin-bottom: 0;
}
// .ant-row{
//   margin-bottom: 8px;
// }
.ant-row:last-child {
  margin-bottom: 0;
}
</style>
