var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"common-detail-view W100"},[_c('ak-container',{staticClass:"header-info-main",attrs:{"type":"white","card":"","bodyStyle":{ padding: 0 },"loading":_vm.loading}},[_c('DetailTop',{attrs:{"businessResp":_vm.businessResp,"personalResp":_vm.personalResp}}),_c('div',{staticClass:"authInfo"},[_c('div',{staticClass:"bussinessReal"},[_c('a-card',[_c('div',{staticClass:"F16 F-BOLD",staticStyle:{"font-weight":"bold","margin-bottom":"10px"}},[_vm._v(" 企业真实性认证信息 ")]),_c('a-row',{staticClass:"PB16",attrs:{"gutter":_vm.gutter}},[_c('a-col',{attrs:{"span":4}},[_c('span',[_vm._v("企业名称 ")])]),_c('a-col',{attrs:{"span":20}},[_vm._v(_vm._s(_vm.realBusiCertification.enterpriseName))])],1),(!_vm.realBusiCertification)?_c('a-row',[_vm._v("营业执照")]):_vm._e(),(!_vm.realBusiCertification)?_c('a-row',[_c('viewer',{attrs:{"images":[
                `${_vm.$const.OssImagePrefix}/${_vm.realBusiCertification.busiLicensePic}`,
              ]}},[_c('img',{staticClass:"detail-img",attrs:{"src":`${_vm.$const.OssImagePrefix}/${_vm.realBusiCertification.busiLicensePic}`}})])],1):_vm._e(),(!_vm.realBusiCertification)?_c('a-row',{staticClass:"PB16",attrs:{"gutter":_vm.gutter}},[_c('a-col',{attrs:{"span":4}},[_c('span',[_vm._v("社会统一信用代码")])]),_c('a-col',{attrs:{"span":20}},[_vm._v(_vm._s(_vm.realBusiCertification.busiLicenseCode))])],1):_vm._e(),_c('a-row',{staticClass:"PB16",attrs:{"gutter":_vm.gutter}},[_c('a-col',{attrs:{"span":4}},[_c('span',[_vm._v("营业执照有效期")])]),_c('a-col',{attrs:{"span":20}},[_vm._v(_vm._s(_vm.realBusiCertification.busiLicenseAvailable))])],1),_c('a-row',{staticClass:"PB16",attrs:{"gutter":_vm.gutter}},[_c('a-col',{attrs:{"span":4}},[_c('span',[_vm._v("法人姓名")])]),_c('a-col',{attrs:{"span":20}},[_vm._v(_vm._s(_vm.realBusiCertification.legalmanLicenceName))])],1),_c('a-row',[_vm._v("法人身份证")]),_c('a-row',{staticClass:"PB16",attrs:{"gutter":_vm.gutter}},[(_vm.realNameCertification&&_vm.realNameCertification.handIdentityCard !== '')?_c('a-col',{staticClass:"img-padding",attrs:{"span":6}},[_c('viewer',{attrs:{"images":[
                  `${_vm.$const.OssImagePrefix}/${_vm.realNameCertification.handIdentityCard}`,
                ]}},[_c('img',{staticClass:"detail-img",attrs:{"src":`${_vm.$const.OssImagePrefix}/${_vm.realNameCertification.handIdentityCard}`}})])],1):_vm._e(),(_vm.realNameCertification&&_vm.realNameCertification.frontIdentityCard !== '')?_c('a-col',{staticClass:"img-padding",attrs:{"span":6}},[_c('viewer',{attrs:{"images":[
                  `${_vm.$const.OssImagePrefix}/${_vm.realNameCertification.frontIdentityCard}`,
                ]}},[_c('img',{staticClass:"detail-img",attrs:{"src":`${_vm.$const.OssImagePrefix}/${_vm.realNameCertification.frontIdentityCard}`}})])],1):_vm._e(),(_vm.realNameCertification&&_vm.realNameCertification.backIdentityCard !== '')?_c('a-col',{attrs:{"span":6}},[_c('viewer',{attrs:{"images":[
                  `${_vm.$const.OssImagePrefix}/${_vm.realNameCertification.backIdentityCard}`,
                ]}},[_c('img',{staticClass:"detail-img",attrs:{"src":`${_vm.$const.OssImagePrefix}/${_vm.realNameCertification.backIdentityCard}`}})])],1):_vm._e()],1)],1)],1),_c('div',{staticClass:"infoCertification"},[_c('a-card',[_c('div',{staticClass:"F16 F-BOLD",staticStyle:{"font-weight":"bold","margin-bottom":"10px"}},[_vm._v(" 简介认证 ")]),_c('p',[_vm._v(" "+_vm._s(_vm.authentication && _vm.authentication.introduction)+" ")]),_c('div',{staticClass:"F16 F-BOLD",staticStyle:{"font-weight":"bold","margin-bottom":"10px"}},[_vm._v(" 品牌认证 ")]),(_vm.authentication && _vm.authentication.brandCertification)?_c('div',{staticClass:"brandCertification"},[_c('a-row',{staticClass:"PB16",attrs:{"gutter":_vm.gutter}},[_c('a-col',{attrs:{"span":6}},[_vm._v("商标注册证")]),_c('a-col',{attrs:{"span":6}},[_vm._v("商标名称："+_vm._s(_vm.authentication && _vm.authentication.brandCertification.brandName))])],1),_c('a-row',{staticClass:"PB16",attrs:{"gutter":_vm.gutter}},[_c('a-col',{attrs:{"span":6}},[_c('viewer',{attrs:{"images":[
                    `${_vm.$const.OssImagePrefix}/${_vm.authentication?.brandCertification?.brandRegistPic}`,
                  ]}},[_c('img',{staticClass:"detail-img",attrs:{"src":`${_vm.$const.OssImagePrefix}/${_vm.authentication?.brandCertification?.brandRegistPic}`}})])],1),_c('a-col',{attrs:{"span":6}},[_c('viewer',{attrs:{"images":[
                    `${_vm.$const.OssImagePrefix}/${_vm.authentication?.brandCertification?.brandLogoPic}`,
                  ]}},[_c('img',{staticClass:"detail-img",attrs:{"src":`${_vm.$const.OssImagePrefix}/${_vm.authentication?.brandCertification?.brandLogoPic}`}})])],1)],1),_c('div',{staticClass:"F16 F-BOLD",staticStyle:{"font-weight":"bold","margin-bottom":"10px"}},[_vm._v(" 商标授权书 ")]),_c('a-row',{attrs:{"span":6}},[_c('viewer',{attrs:{"images":[
                  `${_vm.$const.OssImagePrefix}/${_vm.authentication?.brandCertification?.brandAuthorizePic}`,
                ]}},[_c('img',{staticClass:"detail-img",attrs:{"src":`${_vm.$const.OssImagePrefix}/${_vm.authentication?.brandCertification?.brandAuthorizePic}`}})])],1)],1):_vm._e(),(_vm.authentication && _vm.authentication.addressCertification)?_c('div',{staticClass:"addressCertification"},[_c('div',{staticClass:"F16 F-BOLD",staticStyle:{"font-weight":"bold","margin-bottom":"10px"}},[_vm._v(" 线下实体店认证 ")]),_c('p',[_vm._v(" "+_vm._s(_vm.authentication && _vm.authentication.addressCertification.shopAdress)+" ")]),(_vm.authentication.addressCertification)?_c('p',[_vm._v(" 营业中    "+_vm._s(_vm.authentication.addressCertification.workDaysBegin)+" - "+_vm._s(_vm.authentication.addressCertification.workDaysEnd)+" "+_vm._s(_vm.authentication.addressCertification.workTimeBegin)+" - "+_vm._s(_vm.authentication.addressCertification.workTimeEnd)+" ")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"F16 F-BOLD",staticStyle:{"font-weight":"bold","margin-bottom":"10px"}},[_vm._v(" 店铺环境认证 ")]),(_vm.authentication && _vm.authentication.officeEnvironmentCertification)?_c('a-row',{staticClass:"PB16",attrs:{"gutter":_vm.gutter}},_vm._l((_vm.authentication.officeEnvironmentCertification),function(expImg){return _c('a-col',{key:expImg,attrs:{"span":6}},[_c('viewer',{attrs:{"images":[`${_vm.$const.OssImagePrefix}/${expImg}`]}},[_c('img',{staticClass:"detail-img",attrs:{"src":`${_vm.$const.OssImagePrefix}/${expImg}`}})])],1)}),1):_vm._e(),_c('div',{staticClass:"F16 F-BOLD",staticStyle:{"font-weight":"bold","margin-bottom":"10px"}},[_vm._v(" 类目认证 ")]),_vm._l((_vm.authentication &&
            _vm.authentication.experienceCertifications),function(exp){return _c('div',{key:exp},[_c('div',{staticClass:"F16 F-BOLD",staticStyle:{"font-weight":"bold","margin-bottom":"10px"}},[_vm._v(" 报关单 ")]),_c('p',[_vm._v(_vm._s(exp && exp.timePeriod)+" "+_vm._s(exp && exp.title))]),_c('a-row',{staticClass:"PB16",attrs:{"gutter":_vm.gutter}},[_c('a-col',{attrs:{"span":6}},[_c('ak-viewer',{staticClass:"fileList",model:{value:(_vm.fileList),callback:function ($$v) {_vm.fileList=$$v},expression:"fileList"}})],1),_c('a-col',{attrs:{"span":6}},[_c('ak-viewer',{staticClass:"fileList",model:{value:(_vm.fileList),callback:function ($$v) {_vm.fileList=$$v},expression:"fileList"}})],1),_c('a-col',{attrs:{"span":6}},[_c('ak-viewer',{staticClass:"fileList",model:{value:(_vm.fileList),callback:function ($$v) {_vm.fileList=$$v},expression:"fileList"}})],1)],1)],1)}),_vm._l((_vm.authentication && _vm.authentication.awardCertifications),function(awa){return _c('div',{key:awa},[_c('div',{staticClass:"F16 F-BOLD",staticStyle:{"font-weight":"bold","margin-bottom":"10px"}},[_vm._v(" 质检报告 ")]),_c('p',[_vm._v(_vm._s(awa && awa.timePeriod)+" "+_vm._s(awa && awa.title))]),_c('a-row',{staticClass:"PB16",attrs:{"gutter":_vm.gutter}},[_c('a-col',{attrs:{"span":6}},[_c('ak-viewer',{staticClass:"fileList",model:{value:(_vm.fileList),callback:function ($$v) {_vm.fileList=$$v},expression:"fileList"}})],1),_c('a-col',{attrs:{"span":6}},[_c('ak-viewer',{staticClass:"fileList",model:{value:(_vm.fileList),callback:function ($$v) {_vm.fileList=$$v},expression:"fileList"}})],1),_c('a-col',{attrs:{"span":6}},[_c('ak-viewer',{staticClass:"fileList",model:{value:(_vm.fileList),callback:function ($$v) {_vm.fileList=$$v},expression:"fileList"}})],1)],1)],1)}),_vm._l((_vm.authentication &&
            _vm.authentication.exhibitionCertifications),function(exh){return _c('div',{key:exh},[_c('div',{staticClass:"F16 F-BOLD",staticStyle:{"font-weight":"bold","margin-bottom":"10px"}},[_vm._v(" CCC安全认证证书 ")]),_c('p',[_vm._v(_vm._s(exh && exh.timePeriod)+" "+_vm._s(exh && exh.title))]),_c('a-row',{staticClass:"PB16",attrs:{"gutter":_vm.gutter}},[_c('a-col',{attrs:{"span":6}},[_c('ak-viewer',{staticClass:"fileList",model:{value:(_vm.fileList),callback:function ($$v) {_vm.fileList=$$v},expression:"fileList"}})],1),_c('a-col',{attrs:{"span":6}},[_c('ak-viewer',{staticClass:"fileList",model:{value:(_vm.fileList),callback:function ($$v) {_vm.fileList=$$v},expression:"fileList"}})],1),_c('a-col',{attrs:{"span":6}},[_c('ak-viewer',{staticClass:"fileList",model:{value:(_vm.fileList),callback:function ($$v) {_vm.fileList=$$v},expression:"fileList"}})],1)],1)],1)})],2)],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }